import React from 'react'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { Button, Card, CardText, CardTitle, Container, Row } from 'reactstrap'

const Marketing = () => {
    const history = useHistory();

    const services = [
        {
            title: "Etude design & UX",
            description: "Etudier le design et la user experience (UX) d'une page web",
            path: "/marketing/design&ux_study"
        },
        /* {
            title: "Etude design & UX",
            description : "Etudier le design et la user experience (UX) une page web"
        },
        {
            title: "Etude design & UX",
            description : "Etudier le design et user experience (UX) d'une page web"
        } */
    ]

    const HandleNavigate = (path) => {
        history.push(path)
    }

    return (
        <Container className='d-flex justify-content-center'>
            <Row>
                {services?.map((service) => <Card
                    key={service.title}
                    body
                    className="text-center m-2"
                    style={{
                        width: '18rem'
                    }}
                >
                    <CardTitle tag="h5">
                        {service.title}
                    </CardTitle>
                    <CardText>{service.description}
                    </CardText>
                    <Button
                        color="primary"
                        onClick={()=>HandleNavigate(service?.path)}
                    >
                        Accéder
                    </Button>
                </Card>)}

            </Row>

        </Container>
    )
}

export default Marketing