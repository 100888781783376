import { AnalyzeWebURL } from 'Api/Marketing';
import Layout from 'Components/Layout/Layout'
import React, { useState } from 'react'
import { Button, Card, CardBody, CardHeader, Container, Input, Label, Row, Spinner} from 'reactstrap'
import GlobalLoading from "../Components/GlobaLoding";
import AlertMessage from 'Components/AlertMessage';

const DesignUXstudyView = () => {
    const [url, setUrl] = useState(null);
    const [result, setResult] = useState(null);
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = GlobalLoading()

    const HandleChange = (e) => {
        setUrl(e.target.value)
    }

    const HandleWebURLValidation = () => {
        const regex = /^(https?:\/\/)?([\w\-]+\.)+[\w\-]+(\/[\w\-./?%&=]*)?$/;
        if (!regex.test(url)) {
            setError('Lien non valide!')
        }
        //console.log('test', regex.test(url))
        return regex.test(url);
    }

    const StartAnaluzingWebURL = async () => {
        setError(null);
        if (HandleWebURLValidation()) {
            setIsLoading(true);
            try {
                if (url) {
                    const { data } = await AnalyzeWebURL(url);
                    console.log(data)
                    if (data) {
                        setResult(data);
                    }
                }
            } catch (error) {
                setError(error?.response?.data?.message?.message)
            }
            finally {
                setIsLoading(false);
            }
        }
    }

    return (
        <Layout>
            <Container className="shadow p-5 mb-5 bg-white rounded">
                <Label> Mettre l' URL d'une page Web</Label>

                <Row className="justify-content-center shadow-none p-5 mb-5 bg-light rounded">
                    <Input
                        className={`w-50 ${error && "border border-danger"}`}
                        type='url'
                        placeholder="www.exemple.com"
                        onChange={HandleChange}
                    />
                    <Button
                        color='primary'
                        className='ml-2'
                        onClick={StartAnaluzingWebURL}
                        disabled={!url}
                    >
                        Etudier
                    </Button>
                </Row>
                <Row className='justify-content-center'>
                    {error && <AlertMessage color='danger' message={error} />}
                    {isLoading ?
                        <Spinner
                            color="primary"
                            type="grow"
                        >
                            Loading...
                        </Spinner>
                        :
                        result &&
                        <Card>
                            <CardHeader icon="primary">
                                <h6>{result?.site_url}</h6>
                            </CardHeader>
                            <CardBody style={{ whiteSpace: 'pre-line' }}>
                                {result?.analysis_result}
                            </CardBody>
                        </Card>
                    }
                </Row>
            </Container>
        </Layout>
    )
}

export default DesignUXstudyView