import { BrowserRouter, Switch, Route } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import RegisterView from "./Views.js/RegisterView";
import HomePageView from "Views.js/HomePageView";
import LoginView from "Views.js/LoginView";
import WelcomeView from "Views.js/Welcome";
import PrivateRoute from "PrivateRoute/PrivateRoute";
import MarketingView from "Views.js/MarketingView";
import DesignUXstudyView from "Views.js/DesignUXstudyView";


function App() {
  
  return (
    <BrowserRouter>
      <Switch>
    
        <Route exact  path='/' component={HomePageView} />
        <PrivateRoute exact  path='/home' component={WelcomeView} />
        <PrivateRoute exact  path='/marketing' component={MarketingView} />
        <PrivateRoute exact  path='/marketing/design&ux_study' component={DesignUXstudyView} />

        <Route exact  path='/Signup' component={RegisterView} />
        <Route exact  path='/signin' component={LoginView} />


      </Switch>
    </BrowserRouter>
  );
}

export default App;
